﻿.comparison-step-two {

    @include highlighting;

    .section {
        margin-top: 30px;
    }

    .twitter-typeahead {
        width: 50%;

        @include govuk-media-query($until: tablet) {
            width: 100%;
        }

        .govuk-form-control {
            width: 100%;
            margin-bottom: -2px;
        }
    }

    .tt-menu {
        background-color: white;
        z-index: 99 !important; // accessibility requirement
        .tt-suggestion {
            a {
                display: block;
                padding: $govuk-gutter-half / 2;
                text-decoration: none;
            }

            a strong {
                text-decoration: underline;
            }

            &:hover, a:hover {
                background-color: govuk-colour("light-blue");
                color: white;
                cursor: pointer;
            }
        }

        .tt-cursor {
            background-color: #f9f9f9;
        }
    }

    .search-types {
        .optional-section {
            label {
                @include govuk-font(19, $weight: bold);
                float: left;
                width: 100%;
                margin-bottom: 1em;

                &.paragraph {
                    font-weight: normal;
                }
            }

            .academy-search {
                margin-bottom: 13px;

                label {
                    margin-bottom: 0;
                }

                details {
                    div.panel-indent {
                        margin-bottom: 14px;

                        p {
                            font-size: 16px;
                        }
                    }

                    summary {
                        font-size: 15.2px;

                        .details-marker {
                            font-size: 9.6px;
                            font-weight: normal;
                        }

                        span.summary {
                            font-size: 15.2px;
                            font-weight: normal;
                        }
                    }
                }
            }
        }
    }

    .la-label{
        font-weight: normal!important;
    }
}
