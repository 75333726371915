﻿%banner-box {
    outline: none;
    max-width: 960px;
    margin: 15px auto 0 auto;
}

.header-content {
    //padding-top: 10px;

    @extend %banner-box;

    &__dynamic-header {
        display: none;
        position: relative;
        background-color: #d5e8f3;
        padding: 20px;
        margin: 10px 0;

        &__copy {
            margin-right: 115px;
            font-size: 1em;

            b, strong {
                font-weight: bold;
            }

            p {
                margin-bottom: 5px;
            }

            @include govuk-media-query($until: tablet) {
                margin-right: 0;
                font-size: 14px;
            }
        }

        &__button {
            display: block;
            position: absolute;
            right: 20px;
            top: 30px;
            width: 120px;
            text-align: right;
            font-size: 1em;

            @include govuk-media-query($until: tablet) {
                position: static;
                text-align: left;
                top: auto;
                right: auto;
                margin-top: 10px;
                font-size: 14px;
            }
        }
    }
}

.sticky-div {
    @media (min-height: 480px) {
        position: -webkit-sticky; // required for Safari
        position: sticky;
        top: 0; // required as well.
        z-index: 10000;
        //margin-top: 10px;
    }
}

.banner {
    @extend %banner-box;

    &__comparison-list-info-panel {
        @include govuk-font(16);
        background-color: #D9ECE2;
        padding: 20px;

        .message {
            display: block;
            margin-bottom: 10px;

            @include govuk-media-query($from: desktop) {
                display: inline-block;
                margin-bottom: 0;
            }
        }

        &__count {
            font-weight: bold;
        }

        &__edit-basket {
            margin-left: 0;
            width: 100%;
            color: #0C0C0C !important;

            @include govuk-media-query($from: desktop) {
                margin-left: 15px;
                width: auto;
            }
        }

        &__clear-criteria {
            margin-right: 15px;
            margin-left: 15px;

            @include govuk-media-query($until: tablet) {
                margin-left: 0;
                margin-top: 10px;
                display: block;
            }
        }

        .add-schools {
            display: block;
            margin-top: 10px;
            color: #0C0C0C !important;
            font-weight: bold;
            text-decoration: none;

            @include govuk-media-query($from: tablet) {
                display: inline-block;
                float: right;
                margin-top: 0;
            }
        }

        @include govuk-media-query($until: tablet) {
            padding: 10px;
        }
    }

    div.view-benchmark-charts-wrapper {
        float: right;
        top: -10px;
        position: relative;

        @include govuk-media-query($until: desktop) {
            float: none;
            bottom: 0;
            display: block;
            margin-top: 10px;
            top: 0;
        }

        @include govuk-media-query($until: tablet) {
            margin-top: 1em;
            top: 0;
        }
    }
}

    .js-enabled {

        .js-collapsed, .optional-section {
            display: none;
        }

        .js-expanded {
            display: block !important;
        }

        .accessbility-postback {
            display: none;
        }
    }

    main {
        &:focus {
            outline: none;
        }
    }

    .home-link {
        display: inline-block;
        //margin-top:10px;
        font-size: 15px;
        color: black !important;

        &:hover {
            color: black;
        }

        @include govuk-media-query($until: tablet) {
            margin-bottom: 2px;
            font-size: 14px;
        }
    }

    .breadcrumb-item {
        display: inline-block;
    }

    .pre-content {
        padding-bottom: 10px;

        @include govuk-media-query($until: tablet) {
            padding-bottom: 4px;
        }
    }

    .side-width-wrapper {
        	@include govuk-width-container;
    }

    .top-logo {
        margin-top: 15px;
    }

    .error-section {
        margin: 20px 0;
    }

    .success {
        background-color: darkseagreen;
    }

    .font-xxxsmall {
        font-size: 10px;
        line-height: 1;
    }

    .no-border {
        border-bottom: none !important;
        border-top: none !important;
        border-right: none !important;
        border-left: none !important;
    }

    .latest-year-message {
        margin-top: 15px;
    }

    .text.section {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    @media (min-width: 641px) {
        .page-heading {
            margin-top: 30px !important;
            margin-bottom: 30px !important;
        }
    }

    .charts-section {
        margin-top: 15px;
    }

    span.bold {
        font-weight: bold;
    }

    .no-underline {
        text-decoration: none !important;
    }

    .revenue-box {
        border-top: 1px solid  $govuk-border-colour;
        margin-top: 30px;
        margin-bottom: 30px;

        h3 {
            margin-top: 1em;
        }
    }

    div.download-links {
        margin-top: 10px;
        margin-bottom: 15px;
        margin-left: -5px;

        a, span {
            color: black;
        }
    }

    a.disabled {
        pointer-events: none;
    }

    a.b-school-link:link {
        fill: #1D70B8;
    }

    a.b-school-link:visited {
        fill: #4c2c92;
    }

    a.b-school-link:hover {
        fill: #2e8aca;
    }

    a.b-school-link:active {
        fill: #2e8aca;
    }

    tspan.england-average-tick {
        //@include govuk-font(16);
        font-weight: bold;
    }

    .chart-filter {
        @include govuk-media-query($until: tablet) {
            padding: 0!important;
        }

        select {
            width: 100%;
        }
    }

    .steps {
        margin-top: 25px;
        margin-bottom: 15px;
    }

    .next-button {
        padding-left: 0!important;
        padding-right: 0!important;
    }

    .modal-form-buttons {
        .next-button {
            padding-left: 15px;
            padding-right: 15px;
        }

        .back-button {
            margin-top: 15px;

            @include govuk-media-query($from: desktop) {
                margin-top: 2%;
                margin-left: 15px;
            }
        }
    }

    .chart-container ul, .charts-section__chart-container ul {
        list-style-type: disc;
        padding: inherit;
        padding-top: 0;
    }

    abbr, span {
        .negative-balance {
            color: govuk-colour("red");
        }
    }
    //modal dialog
    dialog {
        display: block;
    }

    .modal {
        position: fixed;
        left: 23%;
        right: 23%;
        top: 25%;
        width: 40%;
        margin: 0 auto;
        border: 1px solid black;
        background: #fff;
        z-index: 100000;
        padding: 2em;
        max-height: 50%;
        overflow: auto;
        max-width: 720px;

        @include govuk-media-query($until: tablet) {
            left: 0;
            right: 0;
            top: 25%;
            width: 75%;

            h1 {
                margin-top: 0.5em;
            }
        }

        table.p8-meanings {
            tr {
                th {
                    width: 50%;
                    font-weight: bold;
                }

                td {
                    width: 50%;
                }

                td:first-child {
                    font-weight: bold;
                }
            }
        }

        table.ks-meanings {
            .header {
                font-weight: bold;
            }

            tr {
                th {
                    border: solid 1px #bfc1c3;
                    text-align: center;
                }

                td {
                    border-top: 1px solid #bfc1c3;
                    border-right: 1px solid #bfc1c3;
                    text-align: center;
                }

                td:first-child {
                    width: 25%;
                    text-align: left;
                    font-weight: bold;
                    background-color: #dee0e2;
                }
            }
        }

        .modal-list {
            list-style: square;
            padding-left: 20px;
        }

        ul {
            list-style-type: inherit;
            margin-left: 1em;
        }

        legend {
            font-weight: normal;
            margin-top: 1em;
        }

        .modal-form-buttons {
            margin-top: 2em;
            font-size: 16px;
        }

        .form-group {
            margin-bottom: 0;
        }
    }

    .modal-overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background: #000;
        opacity: .6;
        z-index: 99999;
    }

    .modal-title {
        font-weight: bold;
        @include govuk-font(24, $weight: bold);
        margin-bottom: 15px;
        padding-top: 15px;
    }

    #js-modal-close, #js-modal-close-bottom {
        @extend %govuk-link;
        float: right;
    }
    //modal dialog
    .float-to-right {
        float: right
    }

    .black {
        color: black !important;
    }

    .sticky.stick {
        margin-top: 0 !important;
        position: fixed;
        top: 0;
        z-index: 10000;
        border-radius: 0 0 0.5em 0.5em;
        width: 50%;
    }

    .white-font {
        color: white !important;
    }

.link-button {
    @extend %govuk-body-s;
    margin-bottom: 0;
    background: none;
    border: none;
    color: #1D70B8;
    text-decoration: underline;
    cursor: pointer;

    @include govuk-media-query($until: tablet) {
        padding-left: 0;
    }

    &:focus:not(:active):not(:hover) {
        border-color: $govuk-focus-colour;
        color: $govuk-focus-text-colour;
        background-color: $govuk-focus-colour;
        box-shadow: 0 2px 0 $govuk-focus-text-colour;
    }
}

    .a-button {
        @include a-button;
    }

    .map-button {
        @include a-button;
        font-size: 19px !important;
    }

    .tabs {
        .a-button {
            color: #1D70B8;
            font-weight: normal;
        }
    }

    .back-to-main-chart-group-button {
        @include a-button;
        margin-top: 1em;
        margin-bottom: 1em;
        display: none;
    }

    .underline {
        text-decoration: underline;
    }

    .chart.c3 svg {
        font: 14px sans-serif;

        @include govuk-media-query($until: tablet) {
            font: 12px sans-serif !important;
        }
    }

    abbr[title] {
        text-decoration-line: none;
        text-decoration-style: dotted;
    }

.orange-warning {
    border-color: govuk-colour("orange");
    background-color: bisque;
    margin-top: 15px;

    @include govuk-media-query($until: tablet) {
        display: inline-flex;

        .shared-icon-wrapper {
            margin-top: auto;
            margin-bottom: auto;
        }
    }
}

    .blue-warning {
        border-color:  $govuk-brand-colour;
        background-color: rgba(210, 226, 241, 1);
        margin-top: 15px;
        display: inline-flex;

        .shared-icon-wrapper {
            margin-top: auto;
            margin-bottom: auto;
        }
        
    }

    .no-padding {
        padding-left: 0!important;
        padding-right: 0!important;
    }

    .no-margin {
        margin: 0 0 0 0;
    }

    .mt-0 {
        margin-top: 0;
    }

    .mt-1 {
        margin-top: 1em;
    }

    .mt-2 {
        margin-top: 2em;
    }

    .ml-05 {
        margin-left: 0.5em;
    }

    .ml-1 {
        margin-left: 1em;
    }

    .ml-2 {
        margin-left: 2em;
    }

    .mt-2 {
        margin-top: 2em;
    }

    .mt-3 {
        margin-top: 3em;
    }

    .mb-0 {
        margin-bottom: 0;
    }

    .mb-05 {
        margin-bottom: 0.5em;
    }

    .mb-1 {
        margin-bottom: 1em;
    }

    .mb-2 {
        margin-bottom: 2em;
    }
    
    .mb-3 {
        margin-bottom: 3em;
    }

    .mr-1 {
        margin-right: 1em;
    }

    .mr-2 {
        margin-right: 2em;
    }

    .mt-header {
        font-size: 24px;
        margin-top: 1.875em;

        @include govuk-media-query($until: tablet) {
            margin-top: 0;
        }
    }

    .mt-15 {
        margin-top: 1.5em;
    }

    .pt-0 {
        padding-top: 0!important;
    }

    .pt-1 {
        padding-top: 1em!important;
    }

    .pt-5 {
        padding-top: 5px!important;
    }

    .pb-0 {
        padding-bottom: 0!important;
    }

    .pb-1 {
        padding-bottom: 1em!important;
    }

    .pl-0 {
        padding-left: 0!important;
    }

    .pr-0 {
        padding-right: 0!important;
    }

    .pl-1 {
        padding-left: 1em!important;
    }

    .pl-2 {
        padding-left: 2em!important;
    }

    .w-100 {
        max-width: 100%;
    }

    .hidden {
        display: none;
    }

    .break-in-mobile {
        @include govuk-media-query($until: tablet) {
            display: block;
        }
    }

    .right-padding {
        padding-left: 0;
        padding-right: 10px;
    }

    .mobile-only {
        @include govuk-media-query($until: tablet) {
            display: block;
        }

        @include govuk-media-query($from: tablet) {
            display: none!important;
        }
    }

    .hide-in-mobile {
        @include govuk-media-query($until: tablet) {
            display: none!important;
        }
    }

    .show-in-desktop {
        display: none;

        @include govuk-media-query($from: desktop) {
            display: inline;
        }
    }

    .back-button {
        margin-top: 2%;


        @include govuk-media-query($until: tablet) {
            margin-top: 25px;
            padding-left: 0;
        }

        font-size: 19px;

        @include govuk-media-query($until: tablet) {
            font-size: 16px;
        }
    }

    .add-remove, .download, .print, .pdf, .save {
        margin: 10px 0 0px 0;
   /*     padding-left: 5px;*/

        a {
            color: black;
        }

        @include govuk-media-query($until: tablet) {
            margin: 0 0 20px 0;
        }

        img.icon {
            vertical-align: middle;
        }
    }


    .chartTable {
        img.icon {
            vertical-align: middle;
            position: relative;
            bottom: 1px;
            @include govuk-media-query($from: desktop)
            {
                right: 0;
            }
        }
    }

    .download {
        img.icon {
            @include govuk-media-query($until: tablet) {
                width: 36px;
            }
        }
    }

    .back-chevron {
        color: govuk-colour("black");
    }

    .forward-chevron {
        color: govuk-colour("dark-grey", $legacy: "grey-2");
        padding-left: 8px;
        padding-right: 8px;
        position: relative;
        top: 2px;
        text-decoration: none;
    }

    span.back-link {
        position: relative;
        left: -3px;
    }

    .icon-back {
        position: relative;
        top: 5px;
        width: 10px;
        height: 15px;
        background-image: url(/public/assets/images/icon-arrow-left.png);
    }

    .icon-report {
        position: relative;
        top: 5px;
        width: 20px;
        height: 20px;
        background-image: url(/public/assets/images/icons/icon-report.png);
    }

    .icon-bookmark {
        position: relative;
        top: 5px;
        width: 20px;
        height: 22px;
        background-image: url(/public/assets/images/icons/add.svg);
    }

    .icon-save-image {
        position: relative;
        top: 5px;
        width: 23px;
        height: 22px;
        background-image: url(/public/assets/images/icons/image.svg);
    }

    .float-to-right {
        float: right;
    }

    .not-bold {
        font-weight: normal;
    }

    .chevron-button {
        text-decoration: none;
        color:  $govuk-text-colour;
        @include govuk-font(19);
    }

    .page-top {
        margin-top: 60px;
        display: inline-block;
    }

    abbr.summary {
        text-decoration: underline;
        text-decoration-style: dotted;
    }

    a.skip-map, .hidden-button {
        color: white;
        font-size: 16px;

        @include govuk-media-query($until: tablet) {
            font-size: 12px;
            font-weight: normal;
        }

        &:focus {
            height: auto;
            outline: 3px solid transparent;
            color: #0b0c0c!important;
            background-color: #fd0;
            -webkit-box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
            box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
            text-decoration: none;
        }

        &:hover {
            cursor: default;
        }
    }

   .tab-link {
        color: #1D70B8;

        @include govuk-media-query($until: tablet) {
            font-size: 14px;
        }

        &:focus {
            height: auto;
            outline: 2px solid #fd0;
            color: #0b0c0c!important;
            text-decoration: none;
        }
    }

    .tt-menu.govuk-form-control.mtm {
        margin-top: 0 !important;
        border: 2px solid $govuk-input-border-colour;
    }

    .bold-form-label {
        font-weight: bold;
        margin-bottom: 10px;
    }
    /*chart table*/
    table.tablesorter {
        .school-column {
            text-align: left;
        }

        thead tr th {
            @include govuk-font(16);
            text-align: right;
            color:  $govuk-brand-colour;
        }

        tbody tr td {
            @include govuk-font(16);
            text-align: right;

            a {
                text-decoration: none;
            }
        }
    }

    table.tablesorter.chart-table {
        //margin-top: 20px;
        margin-bottom: 30px;
    }

table.tablesorter.chart-table th, table.tablesorter.context-table th {
    color:  $govuk-text-colour;
    @include govuk-font(16, $weight: bold);

    &.tablesorter-header {
        color:  $govuk-link-colour;
        font-weight: 500;

        &.tablesorter-headerAsc, &.tablesorter-headerDesc {
            font-weight: 700;
        }
    }

    a {
        text-decoration: none;
    }
}



    .hide-show-button {
        float: right;
    }

    .mobile-hide-show-button {
        float: right;
        margin-bottom:5px;
    }

    .view-charts-tables {
        @include govuk-media-query($from: desktop) {
            float: right;
        }

        margin-bottom: 20px;
    }
    /* table sorter */
    table.tablesorter thead tr .tablesorter-header {
        //background-image: url(/public/assets/images/bg.gif);
        background-repeat: no-repeat;
        background-position: center right;
        cursor: pointer;
        background-position-y: 15px;
    }

    table.tablesorter thead tr .header:hover {
        text-decoration: underline;
    }

    table.tablesorter thead tr .tablesorter-headerDesc {
        background-image: url(/public/assets/images/asc.gif);
        font-weight: bold;
    }

    table.tablesorter thead tr .tablesorter-headerAsc {
        background-image: url(/public/assets/images/desc.gif);
        font-weight: bold;
    }
    /* table sorter */

    /*chart table*/
    body.print-mode .hide-in-print {
        display: none !important;
    }

    .help-icon.help-addremove {
        display: inline;

        .icon {
            float: none;
        }
    }

    .help-icon.help-chart {
        top: -5px;

        @include govuk-media-query($until: tablet) {
            top: 0;
        }
    }

    .inline {
        display: inline;
    }

    .inline-block {
        display: inline-block;
    }

    .panel-border-narrow:last-child {
        margin-bottom: 25px;
    }

    .form-group .panel-border-narrow:last-child {
        margin-bottom: 25px;
    }

    .no-wrap, .nowrap {
        white-space: nowrap;
    }


    .phase-banner {
        margin-bottom: -10px;
    }

    .header-bar {
        height: 10px;
        background-color:  $govuk-brand-colour;
        width: 100%;
        margin: 40px 0 30px 0;

        @include govuk-media-query($until: tablet) {
            margin: 0 0 20px 0;
        }
    }

    .form-group .panel-border-narrow {
        padding-bottom: 15px;
    }

    .form-group.panel-border-narrow {
        padding-bottom: 15px;
    }

div.custom.tabs ul li {
    text-align: center;

    a {
        white-space: normal;
    }

    @include govuk-media-query($until: tablet){
        background-color: govuk-colour("light-grey", $legacy: "grey-3");
        border-color: $govuk-border-colour;
        border-width: 1px;
        margin-right: 5px;
        margin-bottom: 5px;

        &:last-child {
            margin-right: 0;
        }

        button, &.active button {
            border: none;
            margin: 0 auto;
            padding-top: 5px;
        }

        a {
            text-align: center;
        }

        &.active {
            background-color: govuk-colour("white");
        }
    }
}

    .improve-this-page__prompt {
        background-color:  $govuk-brand-colour;
        color: #fff;
        padding: 10px 15px 0;

        a {
            color: #fff;

            &:hover, &:visited {
                color: #fff;
            }

            &:after {
                -webkit-filter: brightness(0) invert(1);
                filter: brightness(0) invert(1);
            }
        }
    }

    #footer {
        border-top: 10px solid  $govuk-brand-colour;
    }

    .full-page-width-wrapper {
        max-width: 960px;
        margin-left: auto;
        margin-right: auto;
    }

    .save-url-input {
        @include govuk-media-query($from: desktop) {
            width: 65%;
            margin-right: 5px;
            position: relative;
            bottom: 1px;
        }

        @include govuk-media-query($until: tablet) {
            margin-bottom: 5px;
        }
    }

    .email-the-link {
        margin-top: 14px;
        display: inline-block;
    }

    .email-list-icon {
        vertical-align: sub;
        margin-right: 5px;
    }

    .shared-icon-wrapper {
        display: inline-block;

        .shared-icon {
            vertical-align: middle;
        }

        margin-right: 0.5em;
    }

    .dfs-panel {
        background-color: #f1f1f1;
        border-color:  $govuk-brand-colour;
        margin-top: 0.8em;
        float: left;
        font-size: 16px;
        //width: 100%;
    }

    .mag-icon {
        margin-right: 3px;
        vertical-align: middle;
        width: 18px;
    }

    .download-link.pdf-ppt {
        display: none;
    }

    .strictmode .download-link.pdf-ppt {
        display: block;
    }

.blue-banner {
    background-color: $govuk-brand-colour;
    color: govuk-colour("white") !important;
    padding: 30px;

    p {
        color: govuk-colour("white") !important;
    }

    hr {
        background: govuk-colour("white");
        border-bottom-color: govuk-colour("white");
    }

    h1 {
        color: #ffffff;
    }
    /*    .start-button {
        background-color: govuk-colour("white");
        color:  $govuk-brand-colour;
        font-weight: 700;
        font-size: 18px;

        &:focus {
            color: black;
        }

        @include govuk-media-query($from: tablet) {
            font-size: 24px;
        }

        .govuk-button__start-icon {
            vertical-align: middle;
            margin-left: 5px;
        }
    }*/

    @media print {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
    }
}

/*Banner header*/
#banner-header {
    width: 100%;
    color: $govuk-text-colour;
    background-color: govuk-colour("yellow");
    padding: 10px 0;
}

#banner-header .header-wrapper {
    max-width: 960px;
    margin: 0 auto;
    padding: 8px 15px;
    font-family: "nta",arial,sans-serif;
}

#banner-header .header-wrapper__header-proposition {
    display: inline-block;
}

#banner-header .header-wrapper__hide-button-wrapper {
    display: inline-block;
    margin-top: 15px;
}

@media (min-width: 641px) {
    #banner-header .header-wrapper__hide-button-wrapper {
        float: right;
        margin-top: 0;
    }
}

#banner-header button {
    color: $govuk-text-colour;
    font-size: 16px;
}

#banner-header a {
    display: block;
    color: $govuk-text-colour;
}

////// GOVUK-FRONTEND modifications/////
.app-accordion--no-open-all {
    .govuk-accordion__open-all{
        display: none;
    }
}

.app-accordion--no-border-bottom {
    border-bottom: none !important;
}

.app-accordion__section-header--no-border-top{
        border-top: none !important;
}

.govuk-button.app-button--start-blue {
    background-color: govuk-colour("white");
    color:  $govuk-brand-colour;

    &:link, &:visited, &:active {
        color:  $govuk-brand-colour !important;
    }

    &:focus, &:hover {
        background-color: govuk-colour("white");
        color:  $govuk-brand-colour;
        color: black !important;//TODO: replace with a govuk colour
    }
}

.govuk-inset-text {

    &.app-govuk-inset-text--orange {
        border-left: 10px solid govuk-colour("orange");
        border-color: govuk-colour("orange");
        background-color: bisque;
        margin-top: 15px;

        @include govuk-media-query($until: tablet) {
            display: inline-flex;

            .shared-icon-wrapper {
                margin-top: auto;
                margin-bottom: auto;
            }
        }

        .govuk-warning-text{
            margin-bottom: 0;
        }
    }

    &.app-govuk-inset-text--narrow {
        border-left-width: 5px;
    }

    &.app-govuk-inset-text--criteria {
        border-left-width: 4px;
        display: inline-block;
        //display: flow-root;
        margin-top: 1rem;
        margin-bottom: 1rem;
        padding-bottom: 0;
    }
}

.govuk-checkboxes--small {
    .govuk-checkboxes__item.app-govuk-checkboxes__item--inline {
        float: none;
        display: inline-block;
    }
}

.govuk-table__header--numeric.app-govuk-table__header--rightest.tablesorter-headerAsc, 
.govuk-table__header--numeric.app-govuk-table__header--rightest.tablesorter-headerDesc{
    padding-right: 15px !important;
}

.govuk-tabs__list-item.app-govuk-tabs__list-item--s {

    @include govuk-media-query($from: tablet) {
        font-size: 0.8rem;
    }

    @include govuk-media-query($from: desktop) {
        font-size: 1.2rem;
    }

/*    height: 35px;

    a {
        vertical-align: sub;
    }*/
}

.govuk-tabs__panel.app-tabs__panel--no-border{
    border: none!important;
    padding: 10px 0 0 0!important;    
}

.govuk-checkboxes__item.app-checkboxes__item--filter {
    float: none !important;
    padding-left: 5px !important;
    margin-left: 10px;

    label {
        padding: 12px 0 10px 30px;
        border: none;
    }
}

.app-navigation {
    font-family: "GDS Transport",arial,sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    //font-weight: 700;
    font-size: 14px;
    font-size: 1rem;
    line-height: 1.25;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    background-color: #f8f8f8;
    border-color: #F3F2F1;
    margin-bottom: 25px;

    @include govuk-media-query($from: mobile) {        
        font-size: 16px;
        line-height: 1.31579;
    }
}

.app-width-container {
    @include govuk-media-query($from: tablet) {
        margin-right: auto;
        margin-left: auto;
    }
 }

.app-navigation--gray-back {
    border-color: govuk-colour("light-grey");
    background-color: govuk-colour("light-grey");
    border-bottom: 1px solid $govuk-border-colour;
}

.app-navigation__list {
    position: relative;
    //left: -15px;
    margin: 0;
    padding: 0;
    list-style: none;
}

.app-navigation__list-item {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    position: relative;
    padding: 0 15px;
    float: left;
    line-height: 50px;
}

.app-navigation__list-item--multiple-rows {
    padding: 0px 10px;
    line-height: 1em;
    max-width: 13%;
    white-space: unset;

    @include govuk-media-query($until: desktop) {
        @include govuk-font(14);
        padding: 0;
        line-height: 20px;
    }

    a {
        height: 40px;
        display: block;
        span{
   /*         line-height: 40px;*/
        }
    }
}

.app-navigation__list-item--current {
    border-bottom: 4px solid #1d70b8;
    font-weight: 700;

    a:focus {
        background-color: unset;
        box-shadow: unset;
        color: $govuk-link-colour;
    }
}

.sticky-chart-controls {
    .govuk-accordion {
        border-bottom: none;
        margin-bottom: 0;

        .govuk-accordion__controls {
            display: none;
        }

        .govuk-accordion__section-content{
            padding-bottom: 0;
        }
    }
}

#chart-filters-selections .govuk-accordion__section-content {
    @include govuk-media-query($from: tablet) {
        display: block;
    }
}

#accordion-finance-filters {
    @include govuk-media-query($from: tablet) {
        display: block;
    }
}

.sad-rating-table {
    width: 100%;
    border-spacing: inherit;

    &--mobile {
        border-spacing: 0 10px;
    }

    .sad-rating-table__rating {
        &--match {
            text-align: center;
            color: govuk-colour("black");

            &.call-out-box {
                padding: 10px 5px;
                margin-bottom: 15px;
                margin-left: auto;
                margin-right: auto;

                @include govuk-media-query($from: tablet) {
                    max-width: 93px;
                }

                @include govuk-media-query($from: desktop) {
                    max-width: 140px;
                }
            }
        }

        &--no-match {
            color: govuk-colour("light-grey");
        }

        &__perc {
            @include govuk-font(36, $weight: bold);
        }

        &__text {
            @include govuk-font(14)
        }
    }

    .sad-rating-table__rating-box {
        height: 10px;

        &--strip {
            height: 18px;
            margin-top: 20px;
        }

        &--mobile {
            width: 65%;
            padding: 0;
        }

        &.Light.green {
            background-color: #7BC987;
        }

        &.Dark.green {
            background-color: #006636;
        }

        &.Red {
            background-color: #CA0600;
        }

        &.Amber {
            background-color: #FFA029;
        }

        &.gray {
            background-color: govuk-colour("light-grey");
        }
    }

    .sad-rating-table__rating-text {
        text-align: center;

        &--mobile {
            text-align: left;
            vertical-align: bottom;
            padding-left: 10px;
        }
    }
}


