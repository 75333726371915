﻿@import "node_modules/govuk-frontend/govuk/helpers/colour";
$progress-well-below: #df3034;
$progress-below: #f47738;
$progress-average: #ffdd00;
$progress-above: #85994b;
$progress-well-above: #006435;

$ofsted-red: #b10e1e;
$ofsted-amber: #f47738;
$ofsted-blue: govuk-colour('blue');
$ofsted-green: #006435;

$dashboard-light-green: #7BC987;
$dashboard-green: #006636;
$dashboard-amber: #FFA029;
$dashboard-red: #CA0600;