/*doc
---
title: Text Filter
name: text-filter
category: Forms
---
```html_example
  <div class="grid-row">
    <div class="column-half">
      <div class="filter-form">
        <form>
          <!-- Search Text Filter -->
          <div class="filter text-filter">
            <label class="legend" for="finder-keyword-search">Search</label>
            <input value="" type="text" name="keywords" id="finder-keyword-search" aria-controls="js-search-results-info" class="text">
          </div>
          <!-- End Search Text Filter -->

          <div class="filter-divider"></div>

          <!-- Dual Text Filter -->
          <div class="filter text-filter">
            <label class="legend" for="issued_date[from]">Issued after</label>
            <input value="" type="text" name="issued_date[from]" id="issued_date[from]" aria-controls="js-search-results-info" aria-describedby="date-help-text" class="text">
            <label class="legend" for="issued_date[to]">Issued before</label>
            <input value="" type="text" name="issued_date[to]" id="issued_date[to]" aria-controls="js-search-results-info" class="text" aria-describedby="date-help-text">
            <span id="date-help-text" class="help-text">For example, 2005 or 21/11/2014<span>
          </span></span>
          </div>
          <!-- End Search Text Filter -->
        </form>
     </div>
    </div>
  </div>
```
*/
.filter-form {
    /*.js-enabled & .button {
    display: none;
  }*/
    .change-search-link {
        padding-left: $govuk-gutter-half;
    }

    p {
        //max-width: $full-width;
        @include govuk-font(16);
        margin-bottom: $govuk-gutter;
    }

    .filter-divider {
        margin-top: $govuk-gutter-half;
    }

    .filter {
        background-color: #f3f2f1;
        margin-bottom: $govuk-gutter-half;
        padding: 5px;
        padding-top: 10px;
        padding-bottom: 10px;

        .legend {
            @include govuk-font(19);
            font-weight: bold;
            display: block;
            padding-bottom: 5px;
            padding-left: 2px;
        }

        label {
            display: block;
        }

        input {
            //padding: 5px;
            width: 95%;
        }

        .text {
            padding-left: 5px;
        }

        select {
            padding: 5px;
            font-size: 16px;
        }
    }

    .radio-filter {
        label {
            display: inline-block;
        }

        input {
            width: auto;
        }
    }

    .button {
        //@include button($button-colour);
        width: auto;
        @include govuk-font(24, $weight: bold);
    }

    .closed {
        .checkbox-container {
            position: absolute;
            left: -9999px;
            padding: 0;
            margin: 0;
        }
    }

    .govuk-grid-row.filter-header {
        margin-top: 0 !important;
        padding-top: 0 !important;

        @include govuk-media-query($until: tablet) {
            display: none;
        }
    }

    .panel.filter-detail {
        border: none;
        padding: 0;
    }

    summary.filter-detail {
        @include govuk-media-query(tablet) {
            display: none;
        }
    }
}

.result-controllers {
    border-top: solid 1px #bfc1c3;
    padding-top: 20px;
    padding-bottom: 65px;

    @include govuk-media-query($until: tablet) {
        padding-bottom: 20px;
    }
}

.filter-secondary-form {
    .filter {
        text-align: right;

        select {
            padding: 5px;
            font-size: 16px;
        }

        @include govuk-media-query($until: tablet) {
            text-align: left;
        }
    }
}
