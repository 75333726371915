﻿.summary-report {
    strong {
        font-weight: bold;
    }

    thead th {
        font-size: 17px;
        font-weight: bold !important;
    }

    .export-link {
        float: right;
        margin-top: -40px;
    }
}
