﻿.simple-characteristics {
    @include highlighting;

    legend {
        display: block;
        clear: left;
    }

    .help-icon.help-chart {
        top: 0;
    }

    .section {
        margin-top: 30px;
    }

    em{
        font-weight: bold;
    }
}
