﻿.benchmark-list {

    @include highlighting;

    button.view-benchmark {
        margin-bottom: 0;
        float: right;
    }

    .edit-basket {
    }

    .benchmarkControlPanel {
        @include govuk-font(16);
        padding-bottom: 15px;
    }

    .benchmarkControls {
        margin-left: 20px;
        margin-right: 20px;
        text-decoration: none;
    }

    li.document {
        border-top: solid 1px $govuk-border-colour;
        padding: $govuk-gutter-half 0;
    }

    .metadata {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            @include govuk-font(14);
            margin-left: 0;
            padding: 0;
        }
    }

    .document .remove-benchmark {
        button {
            float: right;
        }

        button.removefrom {
            margin-right: 0;
        }
    }

    .document .make-benchmark {
        height: 75px;

        @include govuk-media-query($until: tablet) {
            height: 25px;
        }

        button {
            @include govuk-media-query($until: tablet) {
                float: left;
            }
        }
    }

    .document .default-benchmark button {
        float: right;

        @include govuk-media-query($until: tablet) {
            float: left;
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }
    /*    .document .highlight {
        background-color: govuk-colour("pink");
        display: inline;

        a {
            color: white;
            padding: 8px;
            line-height: 1.8em;
        }
    }*/

    .document .highlight-warning {
        background-color: govuk-colour("orange");
        display: inline;

        a {
            color: white;
            padding: 8px;
            line-height: 1.8em;
        }
    }

    .basket-control-buttons {
        display: inline-block;

        @include govuk-media-query($until: tablet) {
            display: block;
            margin-left: -20px;
            margin-top: 15px;
            margin-bottom: 15px;
        }
    }

    .view-button-bottom {
        border-top: solid 1px #bfc1c3;
        padding: 15px 0;
    }

    .help-icon {
        position: relative;
        left: 2px;

        @include govuk-media-query($until: tablet) {
            top: 1px;
            left: 0;
        }
    }
}
