﻿.advanced-school-characteristics {

    .section {
        margin-top: 30px;
    }

    @include highlighting;

    .center-text {
        text-align: center;
    }

    .benchmark-school-value {
        margin-left: 15px;
        margin-bottom: 10px;
        color: govuk-colour("dark-grey", $legacy: "grey-1");
    }

    .remove-button {
        padding-top: 6%;
    }

    .govuk-form-control {
        width: 100%;
    }

    .char-list {
        margin-top: 10px;
        margin-bottom: 40px;
    }

    .input-html .column-half {
        padding-left: 0;
    }

    .validation-summary-errors {
        @include govuk-font(19, $weight: bold);
        color: govuk-colour("red");
    }

    .govuk-form-control.error {
        -moz-box-shadow: none;
        border: 4px solid govuk-colour("red");
    }

    .chkbox {
        position: relative;
        top: 31px;
        cursor: pointer;
        margin: 0;
        width: 29px;
        height: 29px;

        @include govuk-if-ie8 {
            top: 12px;
        }
    }

    .link {
        margin-top: 9px;
    }

    .input-html {
        .form-group {
            margin-bottom: 0;
        }
    }

    form .criteria-questions {
        margin-bottom: 10px;
    }

    .button-set {
        margin-top: 30px;
    }

    details {
        padding-top: 15px;
        padding-bottom: 5px;
        border-top: 2px solid govuk-colour("mid-grey", $legacy: "grey-2");

        summary {
            font-weight: bold;
        }

        .content {
            padding-top: 15px;
        }
    }

    .accordion {
        .multiple-choice {
            float: none;

            input {
                float: none;
            }

            label {
                float: none;
            }
        }
    }

    .criteria-controls {
        display: inline-block;
    }

    .criteria-controls-wrapper {
        display: inline-block;
        top: 10px;
        position: relative;

        @include govuk-media-query($until: tablet) {
            margin-top: 5px;
        }
    }

    .question .panel .form-group .column-half:last-child {
        @include govuk-media-query($until: tablet) {
            margin-top: 13px;
        }
    }

    .school-match-info {
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        padding: 12px 0 10px 0;
        background-color: govuk-colour("white");
    }

    label.govuk-input--error {
        border: none;
        display: block;
    }
}
