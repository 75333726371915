.search-results {

    @include highlighting;

    .manual-button {
        @include govuk-media-query($from: tablet) {
            margin-bottom: 50px;

            &__button {
                float: right;
            }
        }
    }

    .schools-in {
        margin-bottom: 1.5em;
    }

    .back-to-manual-compare {
        @include govuk-media-query($from: tablet) {
            float: right;
        }
    }

    .filter-box {
        float: none;
    }

    .school-document {
        border-bottom: solid 1px $govuk-border-colour;
        padding: $govuk-gutter-half 0;

        .govuk-grid-row {
            margin-right: 0;
        }
        /*&:first-child {
            padding-top: 0;
            margin-top: -2px;
        }*/
    }

    .school-document__add-to-compare-button--add-remove-desktop {
        @include govuk-media-query($from: tablet) {
            display: block;
        }

        @include govuk-media-query($until: tablet) {
            display: none;
        }
    }

    .school-document__add-to-compare-button--add-remove-mobile {
        @include govuk-media-query($until: tablet) {
            display: block;
        }

        @include govuk-media-query($from: tablet) {
            display: none;
        }
    }

    .desktop-button {
        float: right;
        top: 40%;
    }

    .mobile-button {
        width: 25%;
        margin-top: 10px;
        margin-bottom: 10px;
        float: none;
        top: 0;
    }

    .metadata {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            @include govuk-font(14);
            margin-left: 0;
            padding: 0;
            line-height: 1.5em !important;
        }

        .rating-date {
            @include govuk-font(14);
            color: govuk-colour("dark-grey", $legacy: "grey-1");
        }
    }

    .metadata-school-detail {

        dt, dd {
            @include govuk-font(14);
            margin-left: 0;
            padding-bottom: $govuk-gutter-half;

            @media screen and (max-width: 768px) {
                font-size: 16px;
            }

            @include govuk-media-query($from: desktop) {
                padding: 0;
                padding-bottom: $govuk-gutter-half/2;
            }
        }

        dt {
            float: left;
            clear: left;
            width: 40%;

            @include govuk-media-query($from: tablet) {
                width: 40%;
            }
        }

        dd {
            float: left;
            width: 60%;

            @include govuk-media-query($from: tablet) {
                width: 60%;
            }
        }

        a {
            &:after, &:hover:after {
                background-position: right 2px;
                padding-left: 4px;
            }

            text-decoration: none;
        }
    }

    .add-all-wrapper {
        margin-bottom: 60px;
    }

    .add-all {
        float: right;
    }

    div.govuk-option-select.js-collapsible:focus {
        outline: 3px solid #ffdd00;
    }

    nav.navigation-links {
        display: block;
        font-weight: bold;
        float: left;
        padding-top: 5px;

        .olist {
            list-style-type: none;

            .litem {
                display: none;
            }

            .litem.active {
                list-style-type: none;
                display: block;
            }

            .litem a {
                text-decoration: none;
            }

            .litem img {
                vertical-align: middle;
                padding-right: 10px;
            }
        }

        @include govuk-media-query($until: tablet) {
            float: unset;
        }
    }

    div.tabs {
        > div {
            display: none;
        }

        > div.active {
            display: block;
        }

        > div.list {
            padding-top: 10px;
            border-top: solid 1px #bfc1c3;
        }

        > div.map {
            div.form-group {
                margin-top: 5px;

                p {
                    display: none;
                }
            }

            div.form-group.lower {
                margin-top: ($govuk-gutter / 2) + 5px;
            }

            div.form-group.upper {
                margin-bottom: ($govuk-gutter / 2) + 5px;
            }
        }
    }

    div.results-map {
        height: 600px;
        width: 100%;
        position: relative;
        overflow: hidden;

        @include govuk-media-query($until: tablet) {
            height: 400px;
        }
    }

    .infowindow-school-summary {
        padding: 5px;

        a {
            font-weight: bold;
            font-size: 15px;
            color: #1D70B8;
        }

        p {
            margin: 0 0 1px 0;
            font-size: 13px;
        }

        p.lastinsp {
            color: #777;
            font-size: 11.5px;
        }

        p.rating-text {
            font-size: 12px;
            white-space: nowrap;
        }
    }

    .hidden-goto-basket {
        float: right;
        clear: right;
        position: relative;
        top: 10px;
        color: govuk-colour("white");

        &:focus {
            color: govuk-colour("black");
        }

        cursor: default;
        font-size: 16px;
    }

    .count-summary {
        margin-top: 0;
    }

    .js-container-head {
        padding: 2px 0 7px 0 !important;
    }

    .school-table {
        th {
            @include govuk-font(16, $weight: bold);
        }

        td {
            @include govuk-font(14);
        }
    }

    .hidden-filter {
        padding: 0;
        width: 0;
        display: none;
    }

    td.phase {
        text-align: right;
        padding-right: 30px
    }
}
