﻿.sponsor-detail {
    .metadata-school-detail {
        overflow: hidden;

        &__dl {
            margin-bottom: 7px;
            margin-bottom: 0;
        }

        &__dt {
            display: block;
            float: left;
            clear: left;
            padding-right: 15px;
            text-align: left;
            padding-bottom: 15px;
            width: 230px;
            margin-bottom: 0;

            @include govuk-media-query($until: tablet) {
                text-align: left;
                min-width: unset;
                width: 100px;
            }
        }

        &__dd {
            display: block;
            float: left;
            text-align: left;
            padding-right: 5px;
            padding-bottom: 3px;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-bottom: 15px;
            margin-bottom: 0;

            @include govuk-media-query($until: tablet) {
                max-width: 165px;
            }
        }

        a {
            font-weight: initial;
        }

        .seperator {
            top: 1px;
            position: relative;
        }
    }


    .result-list-summary {
        padding-top: 30px;
        padding-bottom: 10px;
    }

    .resultListPanel {
        padding: 15px 0;

        ul.two-columns {
            columns: 2;
            -webkit-columns: 2;
            -moz-columns: 2;
            line-height: 1.5em;

            @include govuk-media-query($until: tablet) {
                columns: 1;
                -webkit-columns: 1;
                -moz-columns: 1;
            }
        }

        a {
            text-decoration: none;
        }
    }

    .chart-wrapper__inner {
        @include govuk-media-query($until: tablet) {
            padding: 0;
        }
    }

    .charts-section {
        &__chart-container {
            &__chart-header {
                margin-top: 1.5em;

                &__h2 {
                    @include govuk-font(24, $weight: bold);
                    margin-top: 1em;
                    margin-bottom: em(10, 20);

                    @include govuk-media-query($from: tablet) {
                        margin-top: em(45, 24);
                        margin-bottom: em(20, 24);
                    }

                    &--inline {
                        display: inline;
                    }
                }

                &__a--inline {
                    display: inline;
                    margin-left: 25px;
                    @include govuk-font(16);

                    @include govuk-media-query($until: tablet) {
                        display: block;
                        margin-left: 0;
                        margin-bottom: 5px;
                    }
                }
            }

            &__chart-header-show-value {
                padding-top: 5px;
            }

            &__accordion-section-header__h2 {
                margin-top: 0;
            }

            &__lastYearBalance {
                @include govuk-font(36);
                border-top: 1px solid $govuk-border-colour;
                color: govuk-colour("turquoise");
                padding-top: 12px;
                font-weight: bold;
            }

            &.index-0 {
                margin-top: 0.5em;
            }
        }
    }

    .chart {
        margin-top: 20px;
        margin-bottom: 20px;

        .c3-line-amount {
            stroke-width: 4px;
            color: govuk-colour("turquoise");
        }

        .c3-axis-y path {
            display: none;
        }

        .c3-axis-x {
            path {
                stroke-width: 2px;
                stroke: $govuk-border-colour;
            }
        }
    }

    .download {
        margin-top: 10px;

        @include govuk-media-query($until: tablet) {
            margin-bottom: 20px;
        }

        img {
            vertical-align: middle;
        }
    }

    .govuk-grid-column-one-quarter.mobile {
        @include govuk-media-query($from: desktop) {
            display: none;
        }

        @include govuk-media-query($until: desktop) {
            display: block;
        }
    }

    .govuk-grid-column-one-quarter.desktop {
        @include govuk-media-query($until: desktop) {
            display: none;
        }

        @include govuk-media-query($from: desktop) {
            display: block;
        }
    }

    .heading-medium {
        abbr, .money {
            font-size: 48px;
            margin-bottom: 3px;
            margin-top: 10px;
        }
    }

    .revenue-box {
        h3 {
            margin-bottom: 5px;
        }
    }

    .govuk-details__text.map {
        border: none;
        padding: 0;
    }

    .school-location-map {
        @include map-focus;
        width: 100%;
        height: 300px;
    }

    summary.map {
        @include govuk-media-query($from: tablet) {
            display: none;
        }
    }

    .infowindow-school-summary {
        padding: 5px;

        a {
            font-weight: bold;
            font-size: 15px;
            color: #1D70B8;
        }

        p {
            margin: 0 0 1px 0;
            font-size: 13px;
        }

        p.lastinsp {
            color: #777;
            font-size: 11.5px;
        }

        p.rating-text {
            font-size: 12px;
            white-space: nowrap;
        }
    }

    .summary-year {
        margin-top: 5px;
    }

    .highlight-warning {
        background-color: govuk-colour("orange");
        padding-left: 10px;
        padding-right: 10px;
        color: govuk-colour("white");
    }

    .chart-border {
        border-top: solid 5px;
        border-color: $govuk-brand-colour;
        padding-top: 15px;
    }

    .chart-accordion-header {
        display: inline;
    }

    .accordion-controls {
        display: none;
    }

    .print img.icon, .download img.icon {
        width: 24px;
        height: 24px;
        padding-right: 5px;
    }

    .resultListPanel__ul {
        margin-bottom: 0;
    }

    .sticky-chart-controls {
        margin-top: 10px;
        background-color: govuk-colour("white");
        border-bottom: 1px solid $govuk-border-colour;

        .form-group {
            margin-bottom: 0;
            padding-bottom: 5px;
            padding-top: 5px;
        }
        /*        .chart-filter {
            height: 75px;
        }

        @include govuk-media-query($from: tablet) {
            height: 93px;

            .chart-filter:first-of-type {
                padding-left: 0;
            }

            .chart-filter:last-of-type {
                padding-right: 0;
            }

            .form-group {
                padding-bottom: 20px;
            }
        }*/
    }

    #historical-charts-accordion {
        border: none;
    }

    .view-charts-tables {
        margin-bottom: 0;
    }

    div.custom.tabs ul {
        @include govuk-media-query($until: tablet) {
            justify-content: flex-start;

            li {

                @include govuk-media-query($until: tablet) {
                    flex-basis: 32%;
                    width: 32%;
                }

                @include govuk-media-query($until: tablet) {
                    flex-basis: 47%;
                    width: 47%;
                }
            }
        }
    }

    .govuk-grid-column-one-quarter.balance-wrapper-mobile--inactive {
        @include govuk-media-query($until: tablet) {
            display: none;
        }
    }

    .govuk-grid-column-one-quarter.balance-wrapper-mobile--active {
        @include govuk-media-query($until: tablet) {
            display: block;
        }
    }

    .trust-ext-link {
        display: block;
        margin-top: 10px;

        @include govuk-media-query($from: tablet) {
            display: inline;
            margin-top: 0;
        }
    }

    .trust-ext-link-seperator {
        display: none;
        margin-left: 5px;
        margin-right: 5px;

        @include govuk-media-query($from: tablet) {
            display: inline;
        }
    }

    .history-table {
        th, td {
            font-size: 16px;

            @include govuk-media-query($until: tablet) {
                font-size: 14px;
            }
        }

        .school-year-column {
            font-weight: bold;
            border-bottom: none;
        }

        .new-row {
            td {
                border-top: 1px double govuk-colour("black");
            }
        }
    }

    div.custom.tabs ul {
        @include govuk-media-query($until: tablet) {
            justify-content: flex-start;

            li {
                flex-basis: 98%;
            }
        }
    }
}
