﻿.guidance {
    list-style-type: inherit;
    //padding-left: 20px;
    margin-bottom: 15px;

    &__list {
        list-style-type: none;
        padding-left: 0;
        line-height: 1em;

        &__item {
            padding-top: 8px;
            padding-bottom: 8px;
        }
    }

    .guidance-table {

        margin-top:15px;

        tr, td, th {
            font-size: 16px;
        }

        .col-right {
            text-align: right;
        }

        caption {
            text-align: left;
            margin-bottom: 10px;
            margin-top: 20px;
            font-weight: bold;
        }
    }
}

.text {
    @include govuk-media-query($from: tablet) {
        margin-top: -25px;
    }
}



