﻿.cookies-settings {
    ul.list {
        margin-bottom: 1em;
        margin-top: 1em;
    }

    legend {
        font-size: 20px;
    }

    .gem-c-notice {
        padding: 20px;
        color:  $govuk-text-colour;
        padding: 15px;
        margin-bottom: 30px;
        clear: both;
        border: 5px solid #1d70b8;
    }
}
