﻿.bic-characteristics {
    /*    .highlight {
        @include govuk-font(16, $weight: bold);
        background-color: govuk-colour("pink");
        color: govuk-colour("white");
        text-align: center;
        padding: 5px;
        display: inline;
    }*/

    @include highlighting;

    .benchmark-school-value {
        margin-bottom: 8px;
        margin-top: 8px;
        color: govuk-colour("dark-grey", $legacy: "grey-1");
    }

    .multiple-choice {
        margin-bottom: 8px;
        margin-top: 8px;
    }

    @include govuk-media-query($from: desktop) {
        .govuk-form-control {
            width: 80%;
        }
    }
}
