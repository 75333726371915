﻿.trust-comparison {

    @include highlighting;

    .remove-new-trust {
        @include govuk-media-query($until: tablet) {
            padding-left: 5px;
            padding-top: 10px;
        }
    }

    .clear-bottom {
        padding-left: 0;
        margin-top: 10px;
    }

    .trust-element {
        margin: 20px 0;
        width: 800px;
        @include govuk-media-query($until: tablet) {
          width: 240px;
        }
    }


    .twitter-typeahead {
        width: 50%;
        float: left;

        @include govuk-media-query($until: tablet) {
            width: 80%;
        }

        .govuk-form-control {
            width: 100%;
            margin-bottom: -2px;
        }
    }

    .tt-menu {
        background-color: white;
        z-index: 99 !important; // accessibility requirement
        .tt-suggestion {
            a {
                display: block;
                padding: $govuk-gutter-half / 2;
                text-decoration: none;
            }

            a strong {
                text-decoration: underline;
            }

            &:hover, a:hover {
                background-color: govuk-colour("light-blue");
                color: white;
                cursor: pointer;
            }
        }

        .tt-cursor {
            background-color: #f9f9f9;
        }
    }

    .control-buttons {
        clear: left;
        padding-top: 25px;

        &.advanced {
            border-top: 1px solid black;
        }
    }

    .govuk-form-control {
        width: 100%;
    }

    .search-types {
        margin-bottom: 30px;
    }

    .trust-match-info {
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        padding: 12px 0 10px 0;
        background-color: govuk-colour("white");
    }

    span.govuk-input--error {
        border: none;
        display: block;
    }

    .benchmark-school-value {
        margin-left: 15px;
        margin-bottom: 10px;
        color: govuk-colour("dark-grey", $legacy: "grey-1");
    }
}
