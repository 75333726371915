﻿.comparison-type-select {
    .pano {

        @include govuk-media-query($until: tablet) {
            margin-top: 30px;
        }
    }

    .blue-svg {
        background: #1d70b8;
        max-height: 140px;
        padding: 40px 0;
        width: 100%;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            /* IE10+ CSS */
            min-height: 150px;
            max-width: 200px;
            padding: 40px 50px 20px 50px;
        }
    }

    .green-svg {
        background: govuk-colour("green");
        padding: 45px 0;
        max-height: 130px;
        width: 100%;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            /* IE10+ CSS */
            min-height: 150px;
            max-width: 200px;
            padding: 40px 50px 20px 50px;
        }
    }

    .turq-svg {
        background: #28a197;
        max-height: 160px;
        padding: 30px 0;
        width: 100%;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            /* IE10+ CSS */
            min-height: 150px;
            max-width: 122px;
            padding: 20px 80px 30px 80px;
        }
    }

    .list-arrow {
        li::before {
            width: 25px;
            height: 25px;
            display: inline-block;
            vertical-align: text-top;
            content: url(../../../public/assets/images/icons/black-arrow.svg);
        }
    }

    .columns {
        margin-top: 0;

        @include govuk-media-query($from: tablet) {
            margin-top: 3em;
        }
    }
}
