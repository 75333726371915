﻿.add-schools-manually {

    @include highlighting;

    .clear-bottom {
        padding-left: 0;
        margin-top: 10px;
    }

    .school-element {
        margin: 20px 0;
        width: 800px;
        @include govuk-media-query($until: tablet) {
          width: 240px;
        }
    }


    .twitter-typeahead {
        width: 100%;
        display: inline !important;

        @include govuk-media-query($until: tablet) {
            width: 80%;
        }

        .govuk-form-control {
            margin-bottom: -2px;
        }
    }

    .tt-menu {
        width: 100%;
        background-color: white;
        z-index: 99 !important; // accessibility requirement
        .tt-suggestion {
            a {
                display: block;
                padding: $govuk-gutter-half / 2;
                text-decoration: none;
            }

            a strong {
                text-decoration: underline;
            }

            &:hover, a:hover {
                background-color: govuk-colour("light-blue");
                color: white;
                cursor: pointer;
            }
        }

        .tt-cursor {
            background-color: #f9f9f9;
        }
    }

    #NewSchoolName {
        vertical-align: baseline !important;
    }

    .control-buttons {
        margin-top: 35px;
    }

    .search-types {
        margin-bottom: 30px;
    }
}
