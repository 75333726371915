﻿.federation-detail {
    .c3-text {
        fill: govuk-colour("black") !important;
    }

    table.bmc-rollover-table {
        background-color: govuk-colour("white");

        td, th {
            @include govuk-font(16);
            border: 1px solid govuk-colour("mid-grey", $legacy: "grey-2");
            padding: 8px;

            &.bold {
                font-weight: bold;
            }
        }

        th {
            background-color: govuk-colour("light-grey", $legacy: "grey-3");
            font-weight: bold;

            &.highlighted {
                background-color: govuk-colour("pink");
                font-weight: bold;
                color: white;
            }
        }
    }

    .qc-glossary {
        clear: both;
        margin-bottom: 15px;

        @include govuk-media-query($from: tablet) {
            float: right;
            margin-bottom: auto;
        }
    }

    .qc-tables {
        margin-top: 10px;

        @include govuk-media-query($from: tablet) {
            margin-top: auto;
        }
    }


    .table-highlight {
        display: inline;
        color: white;
        padding: 6px 8px 6px 8px;
        line-height: 1.8em;
        font-weight: bold;

        @include govuk-media-query($until: tablet) {
            padding: 4px 6px;
        }

        &.index-highlight {
            background-color: govuk-colour("pink");
        }

        &.incompleteFinance-highlight, &.incompleteWorkforce-highlight {
            background-color: govuk-colour("orange");
        }
    }

    .metadata-school-detail {
        overflow: hidden;

        &__dl {
            margin-bottom: 7px;
        }

        &__dt {
            display: block;
            float: left;
            clear: left;
            padding-right: 15px;
            text-align: left;
            width: 140px;
            margin-bottom: 10px;

            @include govuk-media-query($from: desktop) {
                width: 170px;
            }

            @include govuk-media-query($until: tablet) {
                text-align: left;
                min-width: unset;
                width: 100px;
            }
        }

        &__dd {
            display: block;
            float: left;
            text-align: left;
            padding-right: 5px;
            padding-bottom: 3px;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 10px;

            @include govuk-media-query($until: tablet) {
                max-width: 165px;
            }

            @include govuk-media-query($from: tablet) {
                max-width: 165px;
            }

            @include govuk-media-query($from: desktop) {
                max-width: 250px;
            }
        }

        a {
            font-weight: initial;
        }

        .seperator {
            top: 1px;
            position: relative;
        }
    }

    .compare-buttons-panel {
        a.button {
            font-size: 16px;
            width: 100%;
            text-align: center;


            @include govuk-media-query($from: desktop) {
                font-size: 19px;
            }
        }

        &__column-full {
            margin-bottom: 20px;
            //padding: 0 15px;
        }

        &__button--removefrom, &__button--addto, &__button--em {
            @include grey-button;

            @include govuk-media-query($until: tablet) {
                margin-top: 10px;
                margin-left: 0;
            }

            &:focus {
                color: #0b0c0c;
            }
        }

        &__button--auto {
            margin-left: 30px;

            @include govuk-media-query($until: tablet) {
                margin-left: 0;
            }
        }
    }

    .chart-wrapper__inner {
        @include govuk-media-query($until: tablet) {
            padding: 0;
        }
    }

    .charts-section {
        &__chart-container {
            &__chart-header {
                margin-top: 1.5em;

                &__h2 {
                    @include govuk-font(24, $weight: bold);
                    margin-top: 1em;
                    margin-bottom: em(10, 20);

                    @include govuk-media-query($from: tablet) {
                        margin-top: em(45, 24);
                        margin-bottom: em(20, 24);
                    }

                    &--inline {
                        display: inline;
                    }
                }

                &__a--inline {
                    display: inline;
                    margin-left: 25px;
                    @include govuk-font(16);

                    @include govuk-media-query($until: tablet) {
                        display: block;
                        margin-left: 0;
                        margin-bottom: 5px;
                    }
                }
            }

            &__chart-header-show-value {
                padding-top: 5px;
            }

            &__accordion-section-header__h2 {
                margin-top: 0;
            }

            &__lastYearBalance {
                @include govuk-font(36);
                border-top: 1px solid $govuk-border-colour;
                color: govuk-colour("turquoise");
                padding-top: 12px;
                font-weight: bold;
            }
        }
    }

    .chart {
        margin-top: 20px;
        margin-bottom: 20px;

        .c3-line-amount {
            stroke-width: 4px;
            color: govuk-colour("turquoise");
        }

        .c3-axis-y path {
            display: none;
        }

        .c3-axis-x {
            path {
                stroke-width: 2px;
                stroke: $govuk-border-colour;
            }
        }
    }

    .school-location-map {
        @include map-focus;
        width: 100%;
        height: 300px;
    }

    summary.map {
        @include govuk-media-query($from: tablet) {
            display: none;
        }
    }

    .govuk-details__text.map {
        border: none;
        padding: 0;
    }

    .govuk-grid-column-one-quarter.mobile {
        @media(min-width: 1000px) {
            display: none;
        }

        @media(max-width: 1000px) {
            display: block;
            width: 100%;
        }
    }

    .govuk-grid-column-one-quarter.desktop {
        @media(max-width: 1000px) {
            display: none;
        }

        @media(min-width: 1000px) {
            display: block;
        }
    }

    .heading-medium {
        abbr, .money {
            font-size: 48px;
            margin-bottom: 3px;
            margin-top: 10px;
        }
    }

    .revenue-box {
        h2 {
            margin-bottom: 5px;
        }
    }

    .summary-year {
        margin-top: 5px;
    }

    .chart-border {
        border-top: solid 5px;
        border-color: $govuk-brand-colour;
        padding-top: 15px;
    }

    .chart-accordion-header {
        display: inline;
    }

    .accordion-controls {
        display: none;
    }

    .ofsted-divider {
        display: none;

        @include govuk-media-query($from: desktop) {
            display: inline;
            padding: 4px;
        }
    }

    .returns-incomplete {
        &.set-unset, &.addto, &.compare, &.auto {
            display: none !important;
        }
    }

    .compare-buttons-panel__button--compare {
        margin-bottom: 10px;
    }

    .img-button {
        display: inline-block;
        text-decoration: none;
        color: black;
        font-weight: bold;

        img.icon {
            vertical-align: middle;
            padding: 5px;
        }
    }

    .add-remove img.icon, .print img.icon, .download img.icon {
        width: 24px;
        height: 24px;
        padding-right: 5px;
    }

    .post-map-content {
        margin-bottom: 15px;
    }

    .sticky-chart-controls {
        margin-top: 10px;
        background-color: govuk-colour("white");
        border-bottom: 1px solid $govuk-border-colour;

        .form-group {
            margin-bottom: 0;
            padding-bottom: 5px;
            padding-top: 5px;
        }
        /*
        .chart-filter {
            height: 75px;
        }*/
        /*        @include govuk-media-query($from: tablet) {
            height: 93px;

            .chart-filter:first-of-type {
                padding-left: 0;
            }

            .chart-filter:last-of-type {
                padding-right: 0;
            }

            .form-group {
                padding-bottom: 20px;
            }
        }*/
    }

    #historical-charts-accordion {
        border: none;
    }

    .view-charts-tables {
        margin-bottom: 0;

        &--qc {
            margin-bottom: 1em;
        }
    }

    div.custom.tabs ul {
        @include govuk-media-query($until: tablet) {
            justify-content: flex-start;

            li {

                @include govuk-media-query($until: tablet) {
                    flex-basis: 32%;
                    width: 32%;
                }

                @include govuk-media-query($until: tablet) {
                    flex-basis: 47%;
                    width: 47%;
                }
            }
        }
    }

    .column-quarter.balance-wrapper-mobile--inactive {
        @include govuk-media-query($until: tablet) {
            display: none;
        }
    }

    .column-quarter.balance-wrapper-mobile--active {
        @include govuk-media-query($until: tablet) {
            display: block;
        }
    }

    .analytics-links {
        border-top: 2px solid $govuk-brand-colour;
        margin-top: 36px;

        @include govuk-media-query($until: tablet) {
            margin-top: 24px;
            margin-bottom: 24px;
        }
    }

    .infowindow-school-summary {
        padding: 5px;

        a {
            font-weight: bold;
            font-size: 15px;
            color: #1D70B8;
        }

        p {
            margin: 0 0 1px 0;
            font-size: 13px;
        }

        p.lastinsp {
            color: #777;
            font-size: 11.5px;
        }

        p.rating-text {
            font-size: 12px;
            white-space: nowrap;
        }
    }

    .dashboard-panel {
        background-color: govuk-colour("light-grey");
        border: 1px solid #F3F2F1;
        padding: 15px 35px 20px 35px;

        @include govuk-media-query($until: tablet) {
            padding: 15px 15px 10px 15px;
        }
    }

    .call-out-box-wrapper {
        padding: 0 5px;

        @include govuk-media-query($until: tablet) {
            padding: 15px 25px;
        }
    }

    .call-out-box {
        background-color: govuk-colour("white");
        padding: 1em;
        text-align: center;
        border: 1px solid $govuk-border-colour;
        box-shadow: 1px 1px #CCCCCC;
        border-radius: 5px;
        position: relative;

        @include govuk-media-query(tablet) {
            height: 160px;
        }

        @include govuk-media-query($from: 1000px) {
            height: auto;
        }
        /*        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 0;
            height: 0;
            border: 0.7em solid transparent;
            border-top-color: govuk-colour("white");
            border-bottom: 0;
            margin-left: -0.7em;
            margin-bottom: -0.7em;
            filter: drop-shadow(0 0.12rem 0.0625rem $govuk-border-colour);
        }*/

        &::before {
            content: "";
            position: absolute;
            top: 97%;
            left: 48%;
            height: 20px;
            width: 20px;
            background: #fff;
            box-sizing: border-box;
            transform: rotate( 45deg ) translate(-50%);
            border-bottom: inherit;
            border-right: inherit;
            box-shadow: inherit;

            @include govuk-media-query($from:640px, $until: 1024px) {
                top: 99%;
            }

            @include govuk-media-query($from: 1024px) {
                top: 98%;
            }
        }
    }
}
