﻿@mixin highlighting {
    .highlight {
        background-color: govuk-colour("pink");
        display: inline;
        padding: 5px 5px;

        span {
            color: govuk-colour("white");
            line-height: 1.8em;
        }

        a {
            @extend %govuk-link;
            color: govuk-colour("white") !important;

            &:focus {
                color: $govuk-text-colour !important;

                span {
                    color: $govuk-text-colour !important;
                }
            }
        }

        @include govuk-media-query($until: tablet) {
            margin-bottom: 5px;
        }
    }
}

@mixin grey-button {
    background-color: govuk-colour("mid-grey", $legacy: "grey-2");
    color: govuk-colour("black");
    -webkit-box-shadow: 0 2px 0 #7d8185;
    box-shadow: 0 2px 0 #7d8185;
}

@mixin a-button {
    background: none;
    border: none;
    color: black;
    text-decoration: none;
    cursor: pointer;
    padding-left: 0;
    padding-right: 0;
    @include govuk-font(16);
    font-weight: bold;

    &:focus {
        outline: 3px solid $govuk-focus-colour;
        outline-offset: 0;
    }
}

@mixin map-focus {
    &:focus {
        border: 3px solid $govuk-focus-colour;
    }
}