﻿.benchmarking-charts {

    @include highlighting;

    .highlight {
        @include govuk-media-query($until: tablet) {
            line-height: 2;
        }
    }

    .c3-text {
        fill: govuk-colour("black") !important;
    }

    .england-average-bar {
        fill: govuk-colour("mid-grey", $legacy: "grey-2") !important;
    }

    .england-average-tick {
        font-weight: bold;
    }

    .highlight-field {
        background-color: govuk-colour("pink");
        color: white;
        padding-left: 10px;
        padding-right: 10px;
        font-weight: bold;
    }

    .table-highlight {
        display: inline;
        color: white;
        padding: 6px 8px 6px 8px;
        line-height: 1.8em;
        font-weight: bold;

        @include govuk-media-query($until: tablet) {
            padding: 4px 6px;
        }

        &.index-highlight {
            background-color: govuk-colour("pink");
        }

        &.incompleteFinance-highlight, &.incompleteWorkforce-highlight {
            background-color: govuk-colour("orange");
        }
    }

    .charts-section {

        .chart-container {
            /*&.index-0 {
                margin-top: 0.5em;
            }*/
            .chart-header {
                margin-top: 1.5em;

                .help-chart {
                    margin-left: -25px;
                    margin-right: 25px
                }
            }

            h2.inline {
                display: inline;
                margin-right: 25px;
            }

            a.inline, button.inline {
                display: inline;
                white-space: nowrap;

                @include govuk-media-query($until: tablet) {
                    display: block;
                    margin-left: 0;
                    margin-bottom: 5px;
                }
            }

            .accordion-section-header h2 {
                margin-top: 0;
            }

            &.column-full {
                @include govuk-media-query($until: tablet) {
                    padding: 0;
                }
            }
        }

        .chart-container:first-of-type {
            margin-bottom: 15px !important;
        }

        .chart {
            //margin-top: 20px;
            margin-bottom: 20px;

            .c3-axis-x g.tick text tspan {
                fill: #1D70B8;
                cursor: pointer;
            }

            @include govuk-media-query($until: tablet) {
                margin-top: 0;
            }
        }

        .accordion-controls {
            display: none;
        }
    }

    .criteria-details {
        .accordion-controls {
            display: block;
        }
    }

    .chart-accordion-header {
        display: inline;
    }

    .chart-border {
        border-top: solid 5px;
        border-color: $govuk-brand-colour;
        padding-top: 15px;
    }

    .criteria-details {
        margin-top: 10px;
        margin-bottom: 10px;

        table {
            margin-top: 10px;
            margin-bottom: 10px;
        }

        li {
            list-style-type: disc;
            margin-left: 20px;
        }
    }

    .panel.criteria-detail {
        @include govuk-media-query($until: tablet) {
            border: none;
            padding: 0;
        }
    }

    .download {
        img {
            vertical-align: middle;
        }

        margin-top: 10px;
    }

    .not-bold {
        font-weight: normal;
    }

    .selection-filters {
        padding-top: 20px;

        .govuk-form-control {
            @include govuk-media-query($from: tablet) {
                width: 80%;
            }
        }
    }

    .selection-controls {
        @include govuk-media-query($from: tablet) {
            padding-top: 30px;
        }

        .clear-link {
            padding-top: 8px;

            @include govuk-media-query($from: desktop) {
                padding-left: 0;
            }

            @include govuk-media-query($until: tablet) {
                padding-left: 0;
                padding-top: 10px;
            }

            cursor: pointer;
            color: #1D70B8;
        }
    }

    details.selection-details {
        padding-top: 10px;

        @include govuk-media-query($from: desktop) {
            border-bottom: 2px solid govuk-colour("mid-grey", $legacy: "grey-2");
        }

        &.hide-borders {
            padding-top: 0;
            border-bottom: none;
        }
    }

    .search-results {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .chart-selection {
        padding-top: 5px;
        padding-bottom: 10px;
        clear: left;

        @include govuk-media-query($from: desktop) {

            .multiple-choice-header {
                display: inline;
                float: left;
            }

            .multiple-choices {
                display: inline;
                float: left;
            }

            .multiple-choice {
                display: inline;
                float: none;
                padding: 0;
                left: 6px;
                top: -1px;

                [type=checkbox] + label::before {
                    content: "";
                    border: 2px solid;
                    background: transparent;
                    width: 25px;
                    height: 25px;
                    position: relative;
                    top: -4px;
                    left: 15px;
                    float: left;
                }

                [type=checkbox] + label::after {
                    float: left;
                    position: relative;
                    left: -10px;
                    top: 2px;
                }

                label {
                    font-size: 16px;
                    padding: 0;
                    padding-top: 10px;
                }
            }
        }

        hr.tablet-divider {
            clear: left;

            @include govuk-media-query($from: desktop) {
                display: none;
            }
        }

        .add-remove-links {
            margin-top: 1.5em;

            a {
                @include govuk-font(16);
                float: right
            }

            &.table {
                margin-top: 0;
            }
        }
    }

    summary.hide-summary {
        display: none;
    }

    div.show-details {
        display: block;
    }

    .customAdd, .customRemove {
        /*@include govuk-media-query($from: tablet) {
            float: right;
        }*/
    }

    .save-as-image {
        @include govuk-media-query($from: tablet) {
            float: right;
        }
    }

    .customActions {
        padding-left: 0;

        a {
            text-decoration: none;
        }

        @include govuk-media-query($from: tablet) {
            margin-top: 1.5em;

            a {
                padding-left: 10px;
            }
            /*div {
                float: right;
            }*/
        }

        @media (max-width: 769px) {
            //padding-bottom: 15px;
            display: inline-block;
            width: 100%;
            margin-top: 0;

            div, a {
                width: 100%;
                //float: left;
                padding-left: 0;
            }
        }
    }

    .print img.icon, .download img.icon, .pdf img.icon, .save img.icon {
        width: 24px;
        height: 24px;
        padding-right: 5px;
    }

    .download-link {
        float: left;
        margin-right: 32px;
        padding-top: 1em;

        a, span {
            color: black;
        }

        @include govuk-media-query($until: tablet) {
            width: 100%;
            padding: 0;
        }
    }

    table.bmc-rollover-table {
        background-color: govuk-colour("white");

        td, th {
            @include govuk-font(16);
            border: 1px solid govuk-colour("mid-grey", $legacy: "grey-2");
            padding: 8px;

            &.bold {
                font-weight: bold;
            }
        }

        th {
            background-color: govuk-colour("light-grey", $legacy: "grey-3");
            font-weight: bold;

            &.highlighted {
                background-color: govuk-colour("pink");
                font-weight: bold;
                color: white;
            }
        }
    }

    .include-context-table {
        padding-top: 25px;
    }

    li.comparison-tab {
        @include govuk-media-query($from: tablet) {

            background-color: $govuk-link-colour;

            a {
                color: govuk-colour("white");
            }

            a:link, a:visited {
                color: govuk-colour("white") !important;
            }

            a:focus {
                color: $govuk-text-colour !important;
            }

            float: right;
            margin-left: auto;

            &.govuk-tabs__list-item--selected {
                background-color: govuk-colour("white");

                a {
                    color: $govuk-text-colour !important;
                }
            }
        }
    }


    div.custom.tabs ul {
        @include govuk-media-query($until: tablet) {
            justify-content: flex-start;
        }

        li {

            @include govuk-media-query($from: desktop) {
                max-width: 110px;

                a, button, a span {
                    font-size: 14px;
                }
            }

            @media (min-width: 920px) {
                max-width: 140px;

                a, button, a span {
                    font-size: 16px;
                }
            }

            @include govuk-media-query($until: tablet) {
                flex-basis: 32%;
                width: 32%;
            }

            @include govuk-media-query($until: tablet) {
                flex-basis: 47%;
                width: 47%;
            }

            height: auto;

            @include govuk-media-query($until: tablet) {
                a:focus {
                    background-color: initial;
                    padding-top: 20px;
                    margin-top: 0;
                }
            }

            button, a {
                //height: $tabheight + 15;
                padding-right: 15px;
                padding-left: 15px;

                @include govuk-media-query($from: desktop) {
                    display: table-cell;
                    vertical-align: middle;
                    text-align: center;
                }
            }

            &.active {
                button, a {
                    @include govuk-media-query($until: tablet) {
                        border: none;
                    }

                    @include govuk-media-query($from: desktop) {
                        //height: $tabheight + 20;
                    }
                }
            }

            &.comparison-tab {
                background-color: $govuk-brand-colour;

                @include govuk-media-query($from: desktop) {
                    float: right;
                    margin-left: auto;

                    &.active {
                        a {
                            color: $govuk-text-colour;
                        }
                    }
                }

                a {
                    color: govuk-colour("white");
                }
            }
        }
    }

    div.tabs ul li a.helpLink {
        border: none;
        text-decoration: unset;
        height: auto;
        -moz-box-sizing: unset;
        -webkit-box-sizing: unset;
        box-sizing: unset;
        padding: inherit;
        white-space: unset;
        display: inline;
        position: relative;
        right: 25px;
        top: 10px;
        float: right;

        @include govuk-media-query($until: tablet) {
            top: -30px;
        }
    }

    div.tabs ul li a.custom, div.tabs ul li button.custom {
        display: inline-block;
        padding-right: 30px
    }

    div.tabs ul li.active a.custom, div.tabs ul li.active button.custom {
        padding-right: 45px;

        @include govuk-media-query($from: tablet) {
            padding-right: 40px;
        }
    }

    .custom-report .chart-container {
        padding-top: 20px;

        hr {
            margin-top: 15px;
            margin-bottom: 15px;
        }
    }

    .help-image {
        vertical-align: top;
    }

    .chart-scores-header {
        display: none;
        padding: 0;
        line-height: 1.4rem;
        max-width: 75px;
        top: 20px;
        position: relative;

        @include govuk-media-query($until: tablet) {
            top: 0
        }
    }

    .one-click-charts .accordion {
        border: none;
    }

    div.tabs ul li, div.tabs ul li a {

        &.tab-vertical-align {
            margin-top: 9px;

            @include govuk-media-query($from: desktop) {
                margin-top: 0;
            }
        }

        @include govuk-media-query($until: tablet) {
            height: 50px;
        }
    }


    .custom-action-mobile {
        @include govuk-media-query($until: tablet) {
            width: unset !important;
            display: inline;
            float: left;
            margin-right: 15px;
        }
    }

    .ex-icon {
        fill: none;
        stroke: #FFFFFF !important;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-miterlimit: 10;

        &:hover {
            cursor: pointer
        }
    }

    .ex-icon-circle {
        &:hover {
            cursor: pointer
        }
    }

    .ks2-help {
        display: inline-block;

        @include govuk-media-query($until: tablet) {
            display: block;
        }
    }

    .dfs-panel {
        @include govuk-media-query($from: desktop) {
            margin-bottom: 0;
        }

        @include govuk-media-query($until: tablet) {
            margin-top: 0;
        }
    }


    .one-click-charts {
        .dfs-panel {
            @include govuk-media-query($until: tablet) {
                margin-top: 0.8em;
            }
        }
    }

    .sticky-chart-controls, .sticky-custom-controls {
        margin: 0;
        background-color: govuk-colour("white");
        border-bottom: 1px solid $govuk-border-colour;

        .form-group {
            margin-bottom: 0;
            padding-bottom: 5px;
            padding-top: 5px;
        }
        /*        .chart-filter {
            height: 75px;
        }*/
        /*        @include govuk-media-query($from: tablet) {
            height: 93px;

            .chart-filter:first-of-type {
                padding-left: 0;
            }

            .chart-filter:last-of-type {
                padding-right: 0;
            }

            .form-group {
                padding-bottom: 20px;
            }
        }*/
    }

    .bic-chart-wrapper {
        margin-top: 10px;
    }

    .chart-wrapper {

        @include govuk-media-query($from: tablet) {
            max-width: 708px;
        }

        @include govuk-media-query($from: desktop) {
            max-width: 960px;
        }
    }

    .chart-table-wrapper {
        padding: 0 15px;
    }

    .chart-table--mobile-only-view {
        display: none;

        @include govuk-media-query($until: tablet) {
            display: table;
        }
    }

    .chart-table--mobile-above-view {
        display: none;

        @include govuk-media-query($from: tablet) {
            display: table;
        }
    }

    table.chart-table--detail-view.chart-table--mobile-only-view {
        tbody tr td {
            text-align: left;
        }
    }

    .chart-table--detail-view {
        margin-top: 5px;
    }

    .chart-table--detail-view__body {
        border-top: 1px double black;
        border-bottom: 1px double black;

        .col-header {
            //white-space: nowrap;
            font-weight: bold;
            font-size: 14px;
        }
    }
}

.score {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    font-size: 1rem;
    text-align: center;
    padding: 0.2em;
    line-height: 14px;
    margin: 0 0 0 1em;

    @include govuk-media-query($until: tablet) {
        margin: 0;
    }

    &.well-below {
        background-color: #df3034;
        color: #fff;
    }

    &.below {
        background-color: #f47738;
        color: #0C0C0C;
    }

    &.average {
        background-color: #ffdd00;
    }

    &.above {
        background-color: #85994b;
        color: #0C0C0C;
    }

    &.well-above {
        background-color: #006435;
        color: #fff;
    }

    &.unknown {
        background-color: govuk-colour("white");
        border: 2px solid govuk-colour("black");
    }

    &.na {
        background-color: govuk-colour("light-grey", $legacy: "grey-3");
        min-height: 34px;

        @include govuk-media-query($until: tablet) {
            min-height: unset;
        }

        div {
            padding-top: 8%;
        }
    }
}

.modal__score {
    display: inline-block;
    font-size: 0.9rem;
    text-align: center;
    width: 19%;
    margin-left: 0.6em;

    .score {
        font-size: 0.9rem;
        padding: 1em 0.3em;
        margin: 0.5em 0;
    }
}

.modal__score:first-of-type {
    margin-left: 0;
}

.chart-table__score {
    font-size: 1.4rem;
    padding: .4em .2em;
    line-height: 1.4rem;
    margin: 0;
    width: 50%;
    float: right;
}

.chart__score {
    font-size: 1.4rem;
    padding: .21em .2em;
    line-height: 1.4rem;
    margin-right: 0;
    margin-left: 0;
    display: block;
    float: none;

    @include govuk-media-query($until: tablet) {
        font-size: 1.2rem;
        padding: 0.09em .2em;
        line-height: 2.7rem;
        margin: 0.23em 0;
    }
}

table.comp-school-table {
    tbody tr td, thead tr th {
        text-align: left;
    }
}

th.comp-school-table {
    &__ks-col {
        max-width: 110px;
        text-align: center!important;
    }

    &__phase-col {
        max-width: 100px;
    }
}

.chart-table__score__text, .chart__score__text {
    vertical-align: sub;
}
