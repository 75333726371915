﻿@use "variables" as v;

.rating-container {
  display: flex;
  justify-content: space-between;
  min-width: 320px;
  
  @include govuk-media-query($until: tablet) {
    min-width: auto;
    display: block;
    text-align: center;
  }
  
  @include govuk-media-query($from: tablet, $until: desktop) {
    min-width: 200px;
  }
}

.rating-box {
  font-size: 16px;
  padding: govuk-spacing(1) govuk-spacing(3);
  border-radius: 3px;
  width: 80%;
  display: inline-block;
  text-align: center;
  background-color: govuk-colour("light-grey");

  @include govuk-media-query($from: tablet, $until: desktop) {
    width: 67%;
  }
  
  &.Light.green {
    background-color: v.$dashboard-light-green;
  }

  &.Dark.green {
    background-color: v.$dashboard-green;
    color: govuk-colour('white');
  }

  &.Red {
    background-color: v.$dashboard-red;
    color: govuk-colour('white');
  }

  &.Amber {
    background-color: v.$dashboard-amber;
  }

  &.gray {
    background-color: govuk-colour("light-grey");
  }
}

