﻿.comparison-strategy {

    @include highlighting;

    .section {
        margin-top: 30px;
    }

    .bic-back-button {
        margin-top: 1.5%;


        @include govuk-media-query($until: tablet) {
            margin-top: 25px;
            padding-left: 0;
        }
    }
}
